@font-face {
  font-family: 'Kalatexa';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Kalatexa/KaLaTeXaDisplay-Normal.woff2) format('woff2');
}

@font-face {
  font-family: 'CentraNo2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/CentraNo2/CentraNo2-Extrabold.woff2) format('woff2');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/NotoSans/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/NotoSans/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Sarabun/Sarabun-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/Sarabun/Sarabun-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Sarabun/Sarabun-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Sarabun/Sarabun-Bold.ttf) format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl font-bold mb-4;
  }
  h2 {
    @apply text-3xl font-semibold mb-4;
  }
  h3 {
    @apply text-2xl font-medium mb-4;
  }
  h4 {
    @apply text-xl font-medium mb-4;
  }
  h5 {
    @apply text-lg font-medium mb-4;
  }
  ol {
    @apply list-inside list-decimal;
  }
  ul {
    @apply list-inside list-disc;
  }
  
}

@media screen and (min-width: 992px) {
  .bottomShareRib {
    width: 904px !important;
    align-self: center !important;
  }
}

/* Small devices (phones) */
@media (max-width: 576px) {
  .jodit-react-wrapper {
    max-width: 340px;
    width: 100%;
  }
}

/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .jodit-react-wrapper {
    max-width: 540px;
    width: 100%;
  }
}

/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
  .jodit-react-wrapper {
    max-width: 720px;
    width: 100%;
  }
}

/* Extra large devices (large desktops) */
@media (min-width: 993px) and (max-width: 1200px) {
  .jodit-react-wrapper {
    max-width: 960px;
    width: 100%;
  }
}

/* Extra extra large devices (larger desktops) */
@media (min-width: 1201px) {
  .jodit-react-wrapper {
    max-width: 1140px;
    width: 100%;
  }
}


* {
  scroll-behavior: smooth;
}

.NotoSans-font {
  font-family: 'CentraNo2', 'Noto Sans Thai', 'NotoSans';
}
.th-font, .th-font span {
  font-family: 'Noto Sans Thai', 'NotoSans', 'CentraNo2'!important;
}
.en-font {
  font-family:  'Kalatexa', 'Noto Sans Thai', 'NotoSans'!important;
}

.en-font .font-extrabold, .en-font .font-bold, .en-font .font-semibold, .en-font strong {
  font-family: 'CentraNo2', 'Kalatexa', 'Noto Sans Thai', 'NotoSans';
}

::-webkit-scrollbar {
  display: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  position: fixed!important;
}

.container-wrapper {
  @apply w-full;
  @apply px-[10px];
  @apply md:px-[25px];
  @apply lg:px-[30px];
  @apply xl:px-[100px];
}

.container-wrapper.left-only {
  @apply pr-0;
  @apply md:pr-[0px];
  @apply lg:pr-[0px];
  @apply xl:pr-[0px];
}

.container-wrapper.right-only {
  @apply pl-0;
  @apply md:pl-[0px];
  @apply lg:pl-[0px];
  @apply xl:pl-[0px];
}


.content-container {
  @apply container-wrapper;
  @apply my-[48px];
  @apply lg:my-[120px];
  @apply flex;
  @apply flex-col;
  @apply space-y-[80px];
  @apply lg:space-y-[120px];
}

.row {
  @apply flex;
  @apply items-center;
}

.row-between {
  @apply flex;
  @apply justify-between;
  @apply items-center;
}

.row-between-start {
  @apply flex;
  @apply justify-between;
  @apply items-start;
}

.row-between-end {
  @apply flex;
  @apply justify-between;
  @apply items-end;
}

.row-center {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.row-center-start {
  @apply flex;
  @apply justify-start;
  @apply items-center;
}

.row-mobile-col {
  @apply flex;
  @apply flex-col;
  @apply lg:flex-row;
}

.row-mobile-col-md {
  @apply flex;
  @apply flex-col;
  @apply md:flex-row;
}

.row-mobile-col-reverse {
  @apply flex;
  @apply flex-col-reverse;
  @apply lg:flex-row;
}

.row-mobile-col-reverse-md {
  @apply flex;
  @apply flex-col-reverse;
  @apply md:flex-row;
}

.heading1 {
  @apply text-[28px];
  @apply leading-[40px];
  @apply lg:text-[72px];
  @apply lg:leading-[86px];
}

.heading2 {
  @apply text-[27px];
  @apply leading-[38px];
  @apply lg:text-[66px];
  @apply lg:leading-[70px];
}

.heading3 {
  @apply text-[26px];
  @apply leading-[36px];
  @apply lg:text-[60px];
  @apply lg:leading-[54px];
}

.heading5 {
  @apply text-[25px];
  @apply leading-[34px];
  @apply lg:text-[42px];
  @apply lg:leading-[48px];
}

.heading6 {
  @apply text-[24px];
  @apply leading-[32px];
  @apply lg:text-[32px];
  @apply lg:leading-[40px];
}

.heading7 {
  @apply text-[20px];
  @apply leading-[28px];
  @apply lg:text-[24px];
  @apply lg:leading-[32px];
}

.title {
  @apply text-[16px];
  @apply leading-[24px];
  @apply lg:text-[20px];
  @apply lg:leading-[28px];
}

.title2 {
  @apply text-[14px];
  @apply leading-[22px];
  @apply lg:text-[20px];
  @apply lg:leading-[28px];
}

.subtitle {
  @apply lg:text-[14px];
  @apply lg:leading-[20px];
  @apply lg:text-[16px];
  @apply lg:leading-[24px];
}

.subtitle1 {
  @apply text-[14px];
  @apply leading-[22px];
}

.subtitle2 {
  @apply text-[14px];
  @apply leading-[22px];
  @apply lg:text-[16px];
  @apply lg:leading-[24px];
}

.detail-blog-title{

  @apply text-[16px];
  @apply sm:text-[18px];
  @apply md:text-[22px];
  @apply lg:text-[24px];

}

.feat-blog-title{
  @apply text-[16px];
  @apply sm:text-[18px];
  @apply md:text-[22px];
  @apply lg:text-[26px];
}

.rec-blog-title{
  @apply text-[14px];
  @apply sm:text-[15px];
  @apply md:text-[15px];
  @apply lg:text-[17px];
}

.blog-item .subtitle2 p, .blog-item .subtitle2 span {
  font-size: 20px!important
}

.desc-blog {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.desc-blog p, .desc-blog span {
  @apply text-[14px];
  @apply sm:text-[12px];
  @apply md:text-[13px];
  @apply lg:text-[15px];
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobileFont {
  @apply text-[11px];
  @apply sm:text-[12px];
  @apply md:text-[13px];
  @apply lg:text-[15px];
}

.small {
  @apply text-[12px];
  @apply leading-[22px];
  @apply lg:text-[14px];
  @apply lg:leading-[20px];
}

.small2 {
  @apply text-[10px];
  @apply leading-[14px];
  @apply lg:text-[12px];
  @apply lg:leading-[18px];
}

.ed-title {
  @apply font-extrabold;
  @apply text-[32px];
  @apply leading-[34px];
  @apply lg:text-[36px];
  @apply lg:leading-[40px];
}

.ed-subtitle {
  @apply text-[24px];
  @apply leading-[26px];
  @apply lg:text-[24px];
  @apply lg:leading-[28px];
}

.ed-start-price {
  @apply text-[22px];
  @apply leading-[24px];
  @apply lg:text-[20px];
  @apply lg:leading-[28px];
}

.ed-normal-txt {
  @apply lg:text-[1rem];
  @apply text-[0.9rem];
}

.hl-th-subtitle {
  @apply font-normal;
  @apply text-[18px];
  @apply leading-[22px];
  @apply md:text-[18px];
  @apply md:leading-[20px];
}
.circle {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply w-[52px];
  @apply h-[52px];
  @apply rounded-full;
  @apply cursor-pointer;
}
.round-rectangle {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply w-max;
  @apply px-8;
  @apply h-[52px];
  @apply rounded-lg;
  @apply cursor-pointer;
}
.dash {
  width: 100%;
  transform: rotate(-0.07deg);
}
.dash line {
  stroke: #474747;
  stroke-dasharray: 1.8 4.2;
  stroke-linecap: square;
}

.step-shadow {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
}
.pagination-item {
  @apply rounded-full;
  @apply w-[42px];
  @apply h-[42px];
  @apply md:w-[52px];
  @apply md:h-[52px];
  @apply text-center;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply bg-[rgba(6,6,6,0.08)];
  @apply text-[#C7C7C7];
}

.pagination-item.active {
  @apply bg-[#060606];
  @apply text-white;
}

.tag-name {
  @apply bg-[#2A2A2A];
  @apply text-white;
  @apply text-[10px];
  @apply px-[16px];
  @apply py-[4px];
  @apply cursor-pointer;
  @apply rounded-[4px];
  @apply flex;
  @apply items-center;
}

.link {
  @apply text-[#3E92F2];
  @apply underline;
}


.review-box-parent {
  transition: all 0.1s;
}

.mySwiper.hair-review {
  @apply pt-[64px];
  @apply md:pt-[80px];
  @apply overflow-visible;
}

.abs-center {
  @apply absolute;
  @apply left-[50%];
  @apply top-[50%];
  @apply translate-x-[-50%];
  @apply translate-y-[-50%];
}

button:disabled {
  opacity: 60%
}

@media (max-width: 640px) {
  .review-section .swiper-slide.swiper-slide-prev {
    opacity: 0;
  }    
}

.pagination-item {
  @apply rounded-full;
  @apply w-[42px];
  @apply h-[42px];
  @apply md:w-[52px];
  @apply md:h-[52px];
  @apply text-center;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply bg-[rgba(6,6,6,0.08)];
  @apply text-[#C7C7C7];
}

.pagination-item.active {
  @apply bg-[#060606];
  @apply text-white;
}

.tag-name {
  @apply bg-[#2A2A2A];
  @apply text-white;
  @apply text-[10px];
  @apply px-[16px];
  @apply py-[4px];
  @apply cursor-pointer;
  @apply rounded-[4px];
  @apply flex;
  @apply items-center;
}

.link {
  @apply text-[#3E92F2];
  @apply underline;
}

button.big {
  @apply mt-[16px];
  @apply lg:mt-[24px];
  /* @apply w-[180px]; */
  @apply px-[52px];
  @apply h-[52px];
  @apply rounded-[8px];
  @apply row-center;
}

button.big.mobile-full {
  @apply w-full;
  @apply md:w-[unset];
}

button.big.dark {
  @apply bg-[#2A2A2A];
}

button.big.dark > p {
  @apply title;
  @apply text-white;
  @apply font-bold;
}

button.big.light {
  @apply bg-white;
}

button.big.light > p {
  @apply title;
  @apply text-[#060606];
  @apply font-bold;
}

.pill-detail-box {
  @apply h-full;
  @apply rounded-[16px];
  @apply border;
  @apply border-[#DCDCDC];
  @apply p-[12px];
  @apply lg:p-[16px];
}

.abs-center {
  @apply absolute;
  @apply left-[50%];
  @apply top-[50%];
  @apply translate-x-[-50%];
  @apply translate-y-[-50%];
}

@media (max-width: 640px) {
  .review-section .swiper-slide.swiper-slide-prev {
    opacity: 0;
  }    
}

@media print {
  #paper {
    width: 100% !important;
    padding: 10px !important;
  }
}

.zoom-hover {
  transition: all 0.2s;
}

.zoom-hover:hover {
  transform: scale(1.5);
}